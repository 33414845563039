import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody,
    VBPopover,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import gql from "graphql-tag";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/pt-br';

import { Form } from 'vform';

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from './pt.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ADD = gql `
mutation addCommunicated($title: String!, $category_uuid : uuid, $description: String!, $broker_view: Boolean, $real_state_view: Boolean, $expires_at: timestamptz!, $user_uuid: uuid!, $status: Boolean!) {
    insert_communicateds(objects: {title: $title, description: $description, category_uuid : $category_uuid, broker_view: $broker_view, real_state_view: $real_state_view, expires_at: $expires_at, user_uuid: $user_uuid, status: $status}) {
      affected_rows
    }
  }
  `;

const UPDATE = gql `
  mutation updateCommunicated($uuid_communicated : uuid!, $category_uuid : uuid, $title: String!, $description: String!, $broker_view: Boolean!, $real_state_view: Boolean!, $expires_at: timestamptz!, $user_uuid: uuid!, $status: Boolean!) {
    update_communicateds(_set: {title: $title, description: $description,category_uuid : $category_uuid, broker_view: $broker_view, real_state_view: $real_state_view, expires_at: $expires_at, user_uuid: $user_uuid, status: $status}, where: {uuid_communicated: {_eq: $uuid_communicated}}) {
      affected_rows
    }
  }
  `;

const DELETE = gql `
  mutation deleteCommunicated($uuid_communicated : uuid!) {
    delete_communicateds(where: {uuid_communicated: {_eq: $uuid_communicated}}) {
      affected_rows
    }
  }
  `;

const GET_COMMUNICATEDS = gql `
query getCommunicateds {
    communicateds(order_by: {updated_at: desc}) {
      uuid_communicated
      title
      description
      real_state_view
      broker_view
      created_at
      expires_at
      updated_at
      status
      user {
        user_image
        user_fullname
        user_role
      }
      category {
        uuid_category  
        description
        color
      }
    }
  }  
  `;

const GET_CATEGORIES = gql `
query getCategories {
	categories {
		uuid_category
    description
    color
	}
}
  `;

export default {
    components: {
        ToastificationContent,
        quillEditor,
        flatPickr,
        BCardText,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,

        vSelect,
    },
    directives: {
        'b-popover': VBPopover,
    },
    data() {
        return {
            titleForm: 'Adicionar comunicado',
            snowOption: null,

            editMode: false,
            optionsDate: {
                locale: Portuguese,
                enableTime: false,
                dateFormat: 'd/m/Y',
                time_24hr: false,
            },
            expires_at: null,
            categoryCommunicated: null,
            categories: null,
            formCommunicated: new Form({
                uuid_communicated: null,
                title: null,
                description: null,
                real_state_view: false,
                broker_view: false,
                status: true,
            }),
            options: {
                date: {
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                },
            },
            perPage: 5,
            pageOptions: [5, 10, 15],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                { key: 'title', label: 'Titulo', sortable: true },
                { key: 'broker_view', label: 'Corretor(a)', sortable: false },
                { key: 'real_state_view', label: 'Imobiliária', sortable: false },
                { key: 'category.description', label: 'Categoria', sortable: false },
                { key: 'created_at', label: 'Início', sortable: true },
                { key: 'expires_at', label: 'Fim', sortable: true },
                // { key: 'user', label: 'Criado por', sortable: false },
                { key: 'status', label: 'Status', sortable: false },
                { key: 'actions', label: 'Ações', sortable: false },
            ],
            items: [],
        }
    },

    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            } else {
                return null
            }
        },
    },

    created() {
        moment.locale('pt-br')
        if (this.loggedUser) {
            this.getRecords();
        }
    },

    methods: {
        formatDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(6, 4)
            let month = dateValue.substr(3, 2)
            let day = dateValue.substr(0, 2)

            return year + '-' + month + '-' + day;
        },

        formatReverseDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(0, 4)
            let month = dateValue.substr(5, 2)
            let day = dateValue.substr(8, 2)

            return day + '/' + month + '/' + year;
        },

        newCommunicateds() {
            this.titleForm = 'Adicionar comunicado',
                this.editMode = false;
            this.formCommunicated.reset();
            this.expires_at = null
            this.categoryCommunicated = null
            this.$refs['communicatedModal'].show()
        },

        newCommunicated() {
            let {
                title,
                description,
                real_state_view,
                broker_view,
                status,
            } = this.$data.formCommunicated;

            let category_uuid = this.categoryCommunicated.uuid_category



            let expires_at = this.formatDate(this.expires_at)
            this.$apollo.mutate({
                mutation: ADD,
                variables: {
                    title,
                    description,
                    real_state_view,
                    broker_view,
                    status,
                    expires_at,
                    category_uuid,
                    user_uuid: this.loggedUser.uuid_user,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['communicatedModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Comunicado adicionado!',
                        variant: 'success',
                    },
                }, {
                    position: 'bottom-right',
                })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Comunicado não adicionado! ' + error,
                        variant: 'danger',
                    },
                }, {
                    position: 'bottom-right',
                })
            });
        },

        editCommunicated(c) {
            this.titleForm = 'Editar comunicado '
            this.editMode = true;
            this.$data.formCommunicated.fill(c);
            this.expires_at = this.formatReverseDate(c.expires_at);
            this.categoryCommunicated = c.category
            this.$refs['communicatedModal'].show()
        },

        updateCommunicated() {
            let {
                uuid_communicated,
                title,
                description,
                real_state_view,
                broker_view,
                status,
            } = this.$data.formCommunicated;
            let category_uuid = this.categoryCommunicated.uuid_category


            let expires_at = this.formatDate(this.expires_at)
            this.$apollo.mutate({
                mutation: UPDATE,
                variables: {
                    uuid_communicated,
                    title,
                    description,
                    real_state_view,
                    broker_view,
                    status,
                    expires_at,
                    category_uuid,
                    user_uuid: this.loggedUser.uuid_user,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['communicatedModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Comunicado atualizado!',
                        variant: 'success',
                    },
                }, {
                    position: 'bottom-right',
                })
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Erro ao atualizar comunicado! ' + error,
                        variant: 'danger',
                    },
                }, {
                    position: 'bottom-right',
                })
            });
        },

        deleteCommunicated(uuid) {

            this.$bvModal
                .msgBoxConfirm('Deseja realmente excluir este comunicado?.', {
                    title: 'ATENÇÃO!',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.$apollo.mutate({
                            mutation: DELETE,
                            variables: {
                                uuid_communicated: uuid
                            }
                        }).then(() => {
                            this.getRecords();
                            this.$refs['communicatedModal'].hide()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Sucesso',
                                    icon: 'CheckIcon',
                                    text: 'Comunicado deletado!',
                                    variant: 'success',
                                },
                            }, {
                                position: 'bottom-right',
                            })
                        }).catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erro',
                                    icon: 'XIcon',
                                    text: 'Erro ao excluir o comunicado! ' + error,
                                    variant: 'danger',
                                },
                            }, {
                                position: 'bottom-right',
                            })
                        });
                    }
                })


        },

        formatFullDate(data) {
            return moment(data).format("DD/MM/YY [às] HH:mm:ss")
        },

        formatDatetoUpdate(data) {
            return moment(data).format("YYYY-MM-DD")
        },

        getRecords() {
            this.$apollo.query({
                query: GET_COMMUNICATEDS,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.items = data['data']['communicateds'];
                this.totalRows = this.items.length
            });

            this.$apollo.query({
                query: GET_CATEGORIES,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.categories = data['data']['categories'];
                this.totalRows = this.items.length
            });
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
    setup() {
        return {
            avatarText,
        }
    }


}